import React from 'react';
import styled from 'styled-components';

interface RecipeImagesProps {
  imageUrls: Array<string>;
  handleDelete: (index) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const RecipeImageContainer = styled.div`
  position: relative;
`

const RecipeImage = styled.img`
  margin-right: 15px;
  align-self: flex-start;
  max-height: 500px;
`;

const RecipeImageDeleteButton = styled.button`
  width: 25px
  height: 25px
  background-color: red;
  position: absolute;
  top: 0;
  left: 0;
`

const RecipeImages = ({ imageUrls, handleDelete }: RecipeImagesProps) =>
  imageUrls ? (
    <Container>
      {imageUrls.map((url, index) => (
        <RecipeImageContainer>
          <RecipeImageDeleteButton onClick={() => handleDelete(index)}>X</RecipeImageDeleteButton>
          <RecipeImage key={url} src={url} />
        </RecipeImageContainer>
      ))}
    </Container>
  ) : null;

export default RecipeImages;
