import Field from './field';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getIngredients, clearIngredients } from '../actions/ingredients';
import { Ingredient, RecipeIngredient, ReduxAction } from '../types';
import { Button } from './button';

const ListItem = styled.li`
  list-style: none;
  cursor: pointer;
`;

const IngredientFieldContainer = styled.div`
  border: 1px solid black;
  padding: 1%;
  border-radius: 5px;
  margin-bottom: 2%;
`;

interface Props {
  getIngredientsAction: (search?: string) => ReduxAction<Ingredient[]>;
  getClearIngredientsAction: () => ReduxAction<void>;
  handleChange: (props: any) => void;
  handleBlur: (props: any) => void;
  openIngredientModal: Function;
  setFormIngredient: Function;
  filterIngredients: Function;
  ingredient: RecipeIngredient;
  ingredients: Ingredient[];
  index: number;
}

const mapStateToProps = ({ ingredients }) => ({ ingredients });

const mapDispatchToProps = (dispatch) => ({
  getIngredientsAction: (search?: string) => dispatch(getIngredients(search)),
  getClearIngredientsAction: () => dispatch(clearIngredients()),
});

const IngredientField: React.FC<Props> = ({
  handleBlur,
  handleChange,
  ingredient,
  ingredients,
  index,
  getIngredientsAction,
  getClearIngredientsAction,
  openIngredientModal,
  setFormIngredient,
  filterIngredients,
}) => {
  const [showIngredientList, setShowIngredientList] = useState(false);
  const setIngredient = (ingredient) => {
    setFormIngredient(ingredient, index);
    setShowIngredientList(false);
    getClearIngredientsAction();
  };

  return (
    <IngredientFieldContainer>
      <Field
        title="Ingredient Title"
        name={`ingredients[${index}].ingredient_data.title`}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          handleChange(e);

          getIngredientsAction(e.currentTarget.value);
          setShowIngredientList(true);
        }}
        onBlur={handleBlur}
        value={ingredient?.ingredient_data?.title || ''}
      />

      {ingredients && showIngredientList && (
        <ul>
          <ListItem onClick={() => openIngredientModal()}>Create new ingredient</ListItem>
          {ingredients.map((ingredient) => {
            return (
              <ListItem key={ingredient.id} onClick={() => setIngredient(ingredient)}>
                {ingredient.title}
              </ListItem>
            );
          })}
        </ul>
      )}

      <Field
        title="Quantity"
        name={`ingredients[${index}].quantity`}
        type="number"
        onChange={handleChange}
        onBlur={handleBlur}
        value={ingredient?.quantity || 0}
      />

      <Field
        title="Unit (optional)"
        name={`ingredients[${index}].unit`}
        onChange={handleChange}
        onBlur={handleBlur}
        value={ingredient?.unit || ''}
      />

      <Field
        title="Preparation (optional)"
        name={`ingredients[${index}].preparation`}
        onChange={handleChange}
        onBlur={handleBlur}
        value={ingredient?.preparation || ''}
      />

      <Button type="button" onClick={() => filterIngredients(index)} buttonStyle="primary">
        Remove Ingredient
      </Button>
    </IngredientFieldContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(IngredientField);
