import React from 'react';
import styled from 'styled-components';
import { Button } from './button';

import Field from './field';

interface PreparationListProps {
  preparation: string[];
  onChange?: (props: any) => void;
  onBlur?: (props: any) => void;
  setFieldValue?: (...props: any) => void;
}

const StyledDiv = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
`;

const PreparationStepContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PreparationField = styled(Field)`
  width: 100%;
  margin-right: 10px;
`;

const PreparationList = ({ preparation, onBlur, onChange, setFieldValue }: PreparationListProps) => {
  const removePreparationStepByIndex = (index: number) => {
    const updatedPreparationSteps = preparation.filter((step, i) => index !== i);

    setFieldValue('preparation', updatedPreparationSteps);
  };

  return (
    <StyledDiv>
      <h3>Preparation</h3>

      {preparation.map((step, i) => (
        <PreparationStepContainer key={i}>
          <PreparationField
            title={`Step ${i + 1}`}
            value={step}
            onBlur={onBlur}
            onChange={onChange}
            name={`preparation[${i}]`}
          />

          <Button
            type="button"
            buttonStyle="danger"
            onClick={() => {
              removePreparationStepByIndex(i);
            }}
          >
            X
          </Button>
        </PreparationStepContainer>
      ))}

      <Button
        type="button"
        buttonStyle="primary"
        onClick={() => {
          setFieldValue('preparation', [...preparation, '']);
        }}
      >
        Add Step
      </Button>
    </StyledDiv>
  );
};

export default PreparationList;
