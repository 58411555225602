import { Form, Formik, Field as F } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { createIngredient, getParentIngredients } from '../actions/ingredients';
import { Ingredient, ReduxAction, ParentIngredientList, ConstraintList, IngredientModalValues } from '../types';
import { Button } from './button';
import Field from './field';

const Modal = styled.div`
  background: white;
  border: 2px black solid;
  padding: 5%;

  position: fixed;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  margin: -25% 0 0 -25%;
  z-index: 2;

  overflow: scroll;
`;

const ConstraintListDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.li`
  list-style: none;
  cursor: pointer;
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 1%;
`;

const mapStateToProps = ({ ingredient, parentIngredients, constraints }) => ({
  ingredient,
  parentIngredients,
  constraints,
});

const mapDispatchToProps = (dispatch) => ({
  getParentIngredientsAction: (search: string) => dispatch(getParentIngredients(search)),
  createIngredientAction: (ingredient?: Ingredient) => dispatch(createIngredient(ingredient)),
});

interface Props {
  getParentIngredientsAction: (search?: string) => ReduxAction<ParentIngredientList>;
  createIngredientAction: (ingredient?: Ingredient) => ReduxAction<Ingredient>;
  closeIngredientModal: () => void;
  parentIngredients: ParentIngredientList;
  constraints: ConstraintList;
}

const IngredientModal: React.FC<Props> = ({
  createIngredientAction,
  closeIngredientModal,
  getParentIngredientsAction,
  parentIngredients,
  constraints,
}) => {
  const [parentIngredientTitle, setParentIngredientTitle] = useState<string>('');
  const [showParentIngredientList, setShowParentIngredientList] = useState<boolean>(false);

  const initialValues: IngredientModalValues = {
    title: '',
    constraints: [],
    parentId: '',
    parentTitle: '',
  };

  const onSubmit = (body) => {
    const formattedBody = { ...body, constraints: body.constraints.filter((i) => i) };

    createIngredientAction(formattedBody);
    closeIngredientModal();
  };

  const onParentIngredientFocus = () => {
    setShowParentIngredientList(true);
  };

  const SCALE = [
    { label: '1 - Bad', value: 1 },
    { label: '2 - Neutral', value: 2 },
    { label: '3 - Good', value: 3 },
  ];

  return (
    <Modal>
      <CloseButton onClick={closeIngredientModal} buttonStyle="primary">
        X
      </CloseButton>
      <h1>New Ingredient</h1>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: { [key in keyof IngredientModalValues]?: any } = {};
          console.log(values);
          if (!values.title) {
            errors.title = 'Required';
          }
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
          <Form>
            {values.parentId ? (
              <span>
                Parent Ingredient ID: <b>{values.parentId}</b>
              </span>
            ) : (
              <Field
                title="Parent Ingredient"
                name="parentIngredientTitle"
                value=""
                onChange={(e) => {
                  console.log(values);
                  const parent_ingredient_search = e.target.value;
                  getParentIngredientsAction(parent_ingredient_search);
                  setParentIngredientTitle(e.target.value);
                }}
                onBlur={handleBlur}
                onFocus={onParentIngredientFocus}
              />
            )}

            {showParentIngredientList &&
              parentIngredients?.map((parentIngredient, index) => {
                if (parentIngredient.title.includes(parentIngredientTitle)) {
                  return (
                    <ListItem
                      key={index}
                      onClick={() => {
                        setFieldValue('parentId', parentIngredient.id);
                        setShowParentIngredientList(false);
                      }}
                    >
                      {parentIngredient.title}
                    </ListItem>
                  );
                }
              })}
            <Field title="Title" name="title" onChange={handleChange} onBlur={handleBlur} value={values.title} />

            {/* List all possible constraints */}
            <ConstraintListDiv role="group" aria-labelledby="checkbox-group">
              {constraints.map(({ id, title }, i) => (
                <ListItem key={id}>
                  <label>
                    <input
                      type="checkbox"
                      value={id}
                      onChange={() => {
                        const key = `constraints[${i}].id`;

                        if (!values.constraints[i]?.id) {
                          setFieldValue(key, id);
                        } else {
                          setFieldValue(key, undefined);
                        }
                      }}
                    />
                    {title}
                  </label>

                  {/* If constraint selected, show dropdown for scale */}
                  {!!values.constraints[i]?.id && (
                    <>
                      <select
                        name={`constraints[${i}].scale`}
                        value={values.constraints[i].scale}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ display: 'block' }}
                      >
                        <option label="Select scale (1-3)" disabled selected />
                        {SCALE.map(({ label, value }) => (
                          <option value={value} label={label} />
                        ))}
                      </select>
                    </>
                  )}
                </ListItem>
              ))}
            </ConstraintListDiv>

            <Button type="submit" disabled={isSubmitting} buttonStyle="primary">
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(IngredientModal);
