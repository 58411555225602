import React, { useState } from 'react';
import { Formik, Field as F } from 'formik';
import styled from 'styled-components';

import { Recipe, AuthorList } from '../types';
import Field from './field';
import PreparationList from './preparationList';
import IngredientList from './IngredientList';
import IngredientModal from './IngredientModal';
import { Button } from './button';

interface RecipeFormProps {
  recipe: Recipe;
  authors: AuthorList;
  onSubmit: (...props: any) => void;
}

type FormErrors = { [key in keyof Recipe]?: string };

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const RecipeForm = ({ recipe, authors, onSubmit }: RecipeFormProps) => {
  const [showModal, setShowModal] = useState(false);

  const openIngredientModal = () => {
    setShowModal(true);
  };

  const closeIngredientModal = () => {
    setShowModal(false);
  };

  return (
    <Formik
      initialValues={recipe}
      validate={(values) => {
        const errors: FormErrors = {};
        if (!values.title) {
          errors.title = 'Required';
        }
        return errors;
      }}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {({ values, setValues, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
        return (
          <>
            {showModal && <IngredientModal closeIngredientModal={closeIngredientModal} />}
            <StyledForm style={{ filter: showModal ? 'blur(5px)' : '' }} onSubmit={handleSubmit}>
              <h3>Recipe</h3>

              <Field title="Title" name="title" onChange={handleChange} onBlur={handleBlur} value={values.title} />

              <Field
                title="Author"
                value={values.author.id}
                element={
                  <select value={values.author.id} onChange={handleChange} onBlur={handleBlur} name="author.id">
                    {authors?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                }
              />

              <Field
                title="Cuisine"
                name="cuisine"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.cuisine}
              />

              <Field
                title="Cook Time (minutes)"
                type="number"
                name="cookTime"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.cookTime}
              />

              <Field
                title="Serving Size"
                type="number"
                name="servingSize"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.servingSize}
              />

              <Field
                title="Description"
                value={values.description}
                element={
                  <textarea value={values.description} onChange={handleChange} onBlur={handleBlur} name="description" />
                }
              />

              <PreparationList
                preparation={values.preparation}
                onChange={handleChange}
                onBlur={handleBlur}
                setFieldValue={setFieldValue}
              />

              <F
                name="ingredients"
                component={IngredientList}
                ingredients={values.ingredients}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                filterIngredients={(index: number) => {
                  // Remove by index
                  setValues({
                    ...values,
                    ingredients: values.ingredients.filter((ingredient, i) => i !== index),
                  });
                }}
                openIngredientModal={openIngredientModal}
              />

              <Button type="submit" buttonStyle="primary">
                Submit
              </Button>
            </StyledForm>
          </>
        );
      }}
    </Formik>
  );
};

export default RecipeForm;
