import React, { useState } from 'react';
import { Button } from './button';

interface RecipeImageUploadProps {
  recipeId: string;
  onSubmit: Function;
}

const RecipeImageUpload = ({ recipeId, onSubmit }: RecipeImageUploadProps) => {
  const [files, setFiles] = useState<FileList>();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (files) {
      const data = new FormData();

      Array.from(files).forEach((file, i) => data.append(`files[${i}]`, file));
      onSubmit(recipeId, data);
    }
  };

  const onChange = (e) => {
    setFiles(e.target.files);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="file" accept="image/*" name="file" onChange={onChange} multiple={true} />
      <Button type="submit">Upload</Button>
    </form>
  );
};

export default RecipeImageUpload;
