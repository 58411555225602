import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Layout from '../components/layout';
import {
  getRecipe,
  createRecipe,
  updateRecipe,
  uploadRecipeImages,
  clearRecipe,
  deleteRecipe,
  getAuthors,
  getConstraints,
} from '../actions';
import { ReduxAction, Recipe, AuthorList, ConstraintList } from '../types';
import RecipeForm from '../components/recipeForm';
import RecipeImageUpload from '../components/RecipeImageUpload';
import RecipeImages from '../components/RecipeImages';
import { Button } from '../components/button';
import { updateRecipeNoFetch } from '../actions/recipe';

const mapStateToProps = ({ recipe, recipeError, authors, constraints }) => ({
  recipe,
  recipeError,
  authors,
  constraints,
});

const mapDispatchToProps = (dispatch) => ({
  getRecipeAction: (recipeId: string) => dispatch(getRecipe(recipeId)),
  createRecipeAction: (recipe: Recipe) => dispatch(createRecipe(recipe)),
  updateRecipeAction: (recipeId: string, recipe: Recipe) => dispatch(updateRecipe(recipeId, recipe)),
  uploadRecipeImagesAction: (recipeId: string, data: FormData) => dispatch(uploadRecipeImages(recipeId, data)),
  updateRecipeNoFetchAction: (recipeId: string, recipe: Recipe) => dispatch(updateRecipeNoFetch(recipeId, recipe)),
  clearRecipeAction: () => dispatch(clearRecipe()),
  deleteRecipeAction: (recipeId: string) => dispatch(deleteRecipe(recipeId)),
  getAuthorsAction: () => dispatch(getAuthors()),
  getConstraintsAction: () => dispatch(getConstraints()),
});

interface RecipePageProps {
  recipeId: string;
  recipe: Recipe;
  recipeError: string;
  authors: AuthorList;
  getRecipeAction: (recipeId: string) => ReduxAction<Recipe>;
  createRecipeAction: (recipe: Recipe) => ReduxAction<Recipe>;
  updateRecipeAction: (recipeId: string, recipe: Recipe) => ReduxAction<Recipe>;
  updateRecipeNoFetchAction: (recipeId: string, recipe: Recipe) => ReduxAction<Recipe>;
  uploadRecipeImagesAction: (recipeId: string, data: FormData) => ReduxAction<null>;
  clearRecipeAction: () => ReduxAction<null>;
  deleteRecipeAction: (recipeId: string) => ReduxAction<null>;
  getAuthorsAction: () => ReduxAction<AuthorList>;
  getConstraintsAction: () => ReduxAction<ConstraintList>;
}

const RecipePage = ({
  recipeId,
  recipe,
  recipeError,
  authors,
  getRecipeAction,
  createRecipeAction,
  updateRecipeAction,
  updateRecipeNoFetchAction,
  uploadRecipeImagesAction,
  clearRecipeAction,
  deleteRecipeAction,
  getAuthorsAction,
  getConstraintsAction,
}: RecipePageProps) => {  
  const isNewRecipe = recipeId === 'new';

  // Redirect on recipe create
  if (isNewRecipe && recipe && !recipeError) {
    navigate(`/recipes/${recipe.id}`);
  }

  const emptyRecipe: Recipe = {
    id: '',
    preparation: [],
    title: '',
    cookTime: '',
    description: '',
    author: {
      id: 1,
      name: '',
      role: '',
      bio: '',
    },
    images: [],
    ingredients: [],
    allowedConstraints: [],
    similarRecipes: [],
    cuisine: '',
    servingSize: 0,
    recipeStatus: {
      id: 0,
      state: 'unpublished',
    },
  };

  useEffect(() => {
    getAuthorsAction();
    getConstraintsAction();

    if (!isNewRecipe) {
      getRecipeAction(recipeId);
    }
    return () => {
      clearRecipeAction();
    };
  }, [recipeId]);

  const deleteRecipeHandler = () => {
    const isDeleteConfirmed = confirm('Are you sure you want to delete this recipe?');

    if (isDeleteConfirmed) {
      deleteRecipeAction(recipeId);
      navigate('/');
    }
  };

  const handleImageDelete = (index: number) => {
    const images = recipe.images.filter((_, i) => i !== index);
    const data = {...recipe, images};
    updateRecipeNoFetchAction(recipe.id, data)
  }

  return (
    <>
      <ToastContainer />

      <h1>{isNewRecipe ? 'Create a recipe' : `Edit recipe: ${recipe?.title}`}</h1>

      {isNewRecipe && (
        <RecipeForm
          recipe={emptyRecipe}
          authors={authors}
          onSubmit={(body) => {
            createRecipeAction(body);
          }}
        />
      )}

      {recipe && !isNewRecipe && (
        <>
          <RecipeImages imageUrls={recipe.images} handleDelete={handleImageDelete} />
          <RecipeImageUpload recipeId={recipe.id} onSubmit={uploadRecipeImagesAction} />
          <RecipeForm
            recipe={recipe}
            authors={authors}
            onSubmit={(body) => {
              updateRecipeAction(recipeId, body);
            }}
          />
          <Button buttonStyle="danger" onClick={deleteRecipeHandler}>
            Delete
          </Button>
        </>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipePage);
