import React from 'react';
import { Ingredient, RecipeIngredient } from '../types';
import { Button } from './button';
import IngredientField from './IngredientField';

interface Props {
  handleChange: (props: any) => void;
  handleBlur: (props: any) => void;
  setFieldValue: (key: string, value: any) => void;
  filterIngredients: (id: number) => void;
  openIngredientModal: () => void;
  ingredients: RecipeIngredient[];
}

const IngredientList: React.FC<Props> = ({
  handleChange,
  handleBlur,
  setFieldValue,
  filterIngredients,
  ingredients,
  openIngredientModal,
}) => {
  const setIngredient = (ingredient: Ingredient, index: number) => {
    setFieldValue(`ingredients[${index}].ingredient_data.title`, ingredient.title);
    setFieldValue(`ingredients[${index}].ingredient_data.id`, ingredient.id);
  };

  const filterAndRemoveIngredients = (index: number) => {
    filterIngredients(index);
  };

  return (
    <>
      <h3>Ingredients</h3>

      {ingredients.map((ingredient, i) => (
        <div key={`ingredient-${i}`}>
          <IngredientField
            ingredient={ingredient}
            index={i}
            openIngredientModal={openIngredientModal}
            filterIngredients={filterAndRemoveIngredients}
            setFormIngredient={setIngredient}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </div>
      ))}

      <Button
        type="button"
        onClick={() => {
          setFieldValue('ingredients', [...ingredients, { quantity: 0, unit: '', ingredient_data: { title: '' } }]);
        }}
        buttonStyle="primary"
      >
        Add Ingredient
      </Button>
    </>
  );
};

export default IngredientList;
